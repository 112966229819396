import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '../helper/helper.service';
import { Observable } from 'rxjs';
import { ApiRequest } from 'src/app/models/api-request.model';
import { SendDataService } from 'src/app/modules/send/services/send-data.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService,
    private sendData: SendDataService
  ) {}

  initialize(data: {
    walletId: string;
    currency: string;
  }): Observable<ApiRequest> {
    return this.httpClient.patch<ApiRequest>(
      this.helperService.getApiUrl() + 'transactions/initialize',
      {
        ...data,
        transfer: {
          toAmount: this.sendData.toAmount,
          fromAmount: this.sendData.fromAmount,
          rate: this.sendData.rate,
          fee: this.sendData.fee,
          fromCurrency: this.sendData.fromCurrency,
          toCurrency: this.sendData.toCurrency,
          feeCurrency: this.sendData.feeCurrency,
          beneficiary: {
            email: this.sendData.form.email,
            bankName: this.sendData.form.bankName,
            accountName: this.sendData.form.accountName,
            accountNumber: this.sendData.form.accountNumber,
            memo: this.sendData.form.memo,
            bankCode: this.sendData.form.bankCode,
          },
        },
      }
    );
  }

  find(): Observable<ApiRequest> {
    return this.httpClient.get<ApiRequest>(
      this.helperService.getApiUrl() + 'transactions/user'
    );
  }

  findOne(id: string): Observable<ApiRequest> {
    return this.httpClient.get<ApiRequest>(
      this.helperService.getApiUrl() + `transactions/${id}`
    );
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HelperService } from 'src/app/core/helper/helper.service';
import { UploadService } from '../file-upload/upload.service';

@Component({
  selector: 'app-drag-drop-file-upload',
  templateUrl: './drag-drop-file-upload.component.html',
  styleUrls: ['./drag-drop-file-upload.component.scss'],
})
export class DragDropFileUploadComponent {
  uploading = false;
  @Input() title = '';
  label = 'or click here to browse (10MB) Max';
  @Output() uploaded: any = new EventEmitter<any>();

  constructor(
    private helperService: HelperService,
    private uploadService: UploadService
  ) {}

  uploadFile(file: any) {
    if (file.size > 10000000) {
      this.helperService.showError('maximum file size required 10mb', '');
      return;
    }
    this.label = file.name;
    this.uploading = true;
    const form: any = new FormData();
    form.append('file', file);
    this.uploadService.upload(form).subscribe({
      next: (data: any) => {
        this.uploading = false;
        this.helperService.showSuccess(
          'document uploaded successfully',
          'Uploaded!'
        );
        this.uploaded.emit({ file, url: data.data });
      },
    });
  }

  uploadedFile(result: any) {
    this.label = result.file.name
    this.uploaded.emit(result);
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { HelperService } from 'src/app/core/helper/helper.service';
import { UploadService } from './upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  showModal: boolean = false;
  fileData: File = {} as File;
  name: string = '';
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef =
    {} as ElementRef;
  @Input() label: string = '';
  @Output() uploaded: any = new EventEmitter<any>();
  @Output() picked: any = new EventEmitter<any>();
  @Input() color = 'primary';
  @Input() type: any = 'file';
  @Input() link = false;
  @Input() uploading: boolean = false;

  croppedImage: any = '';
  imageChangedEvent: any = '';

  constructor(
    public helperService: HelperService,
    public uploadService: UploadService
  ) {}

  ngOnInit(): void {}

  selectFile() {
    this.fileInput.nativeElement.click();
  }

  fileProgress(fileInput: any) {
    this.name = fileInput.target.files[0]?.name;
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    if (this.fileData.size > 10000000) {
      this.helperService.showError('Size exceed 10mb', 'Upload Error');
      return;
    }
    if (this.type == 'image') {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.picked.emit(e.target!.result);
      };

      reader.readAsDataURL(this.fileData);
    }
    this.startUpload();
  }

  startUpload() {
    this.uploading = true;
    this.label = 'uploading please wait...';
    const form = new FormData();
    form.append('file', this.fileData);
    this.uploadService.upload(form).subscribe({
      next: (data: any) => {
        this.uploading = false;
        this.label = 'Change Image';
        this.uploaded.emit({ file: this.fileData, url: data.data });
      },
    });
  }

  async dataUrlToFile(dataUrl: any, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '../helper/helper.service';
import { Observable } from 'rxjs';
import { ApiRequest } from 'src/app/models/api-request.model';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {}

  create(data: any): Observable<any> {
    return this.httpClient.post(
      this.helperService.getApiUrl() + 'accounts',
      data
    );
  }

  getAddress(
    asset: string,
    network: string,
    beneficiary: any
  ): Observable<ApiRequest> {
    return this.httpClient.post<ApiRequest>(
      this.helperService.getApiUrl() + `user/wallets/didalla`,
      { asset, network, beneficiary }
    );
  }

  findOne(id: string): Observable<any> {
    return this.httpClient.get(
      this.helperService.getApiUrl() + `user/wallets/${id}`,
    );
  }

  find(): Observable<any> {
    return this.httpClient.get(
      this.helperService.getApiUrl() + `user/wallets`,
    );
  }
}

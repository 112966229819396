<div class="uploader">
  <input
    #fileInput
    (change)="fileProgress($event)"
    [accept]="type == 'image' ? '.jpg, .jpeg, .png' : ''"
    type="file"
    class="file-input"
  />
  <div>
    <button
    type="button"
    (click)="selectFile()"
    class="x-btn d-flex align-items-center"
    [ngClass]="{'x-btn-primary': !link, 'x-btn-link': link}"
  >
    <span>{{ label }}</span>
    <app-loading class="ml-1" *ngIf="uploading" color="#fff"></app-loading>
  </button>
  </div>
</div>
<p *ngIf="uploading">{{ name }}</p>

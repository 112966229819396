<div class="form">
  <label for="" class="d-flex align-items-center"> {{ title }} </label>
  <div didallaUpload (fileDropped)="uploadFile($event)" class="upload-box mt-3">
    <img src="assets/images/drop-upload.png" alt="" />
    <img class="hovered" src="assets/images/drop-upload2.png" alt="" />
    <h6>{{ uploading ? "uploading... please wait" : "Drag file here" }}</h6>
    <app-file-upload
      (uploaded)="uploadedFile($event)"
      [link]="true"
      color="#626771"
      [label]="label"
    ></app-file-upload>
    <app-loading *ngIf="uploading"></app-loading>
  </div>
</div>

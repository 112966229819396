<header>
  <div class="header-container">
    <div class="left">
      <div class="logo">
        <img src="assets/images/logo.svg" alt="logo" />
      </div>
    </div>
    <div class="center">
      <nav>
        <a routerLink="/home" routerLinkActive="router-link-active">Home</a>
        <a routerLink="/transactions" routerLinkActive="router-link-active"
          >Transactions</a
        >
        <a routerLink="/receipients" routerLinkActive="router-link-active"
          >Receipients</a
        >
        <a
          routerLink="/account"
          routerLinkActive="router-link-active"
          class="last"
          >Settings</a
        >
      </nav>
    </div>
    <div class="right">
      <div class="buttons">
        <a (click)="logout()" class="logout d-flex align-items-center cursor-pointer">
          <mat-icon svgIcon="feather:log-out"></mat-icon>
          Logout
        </a>
        <a (click)="toggled = true" class="x-btn x-btn-light-outline toggle-btn">
          <mat-icon svgIcon="feather:menu"></mat-icon>
        </a>
      </div>
    </div>
  </div>
</header>

<div [ngClass]="{'show': toggled}" (click)="toggled = false" class="overlay"></div>
<div [ngClass]="{'show': toggled}" class="side-nav">
  <nav>
    <a routerLink="/home" routerLinkActive="router-link-active">Home</a>
    <a routerLink="/transactions" routerLinkActive="router-link-active"
      >Transactions</a
    >
    <a routerLink="/receipients" routerLinkActive="router-link-active"
      >Receipients</a
    >
    <a routerLink="/account" routerLinkActive="router-link-active" class="last"
      >Account</a
    >
    <a (click)="logout()" class="d-flex align-items-center text-danger cursor-pointer">
      <mat-icon svgIcon="feather:log-out"></mat-icon>
      <span class="ms-2">Logout</span>
    </a>
  </nav>
</div>

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { HelperService } from '../helper/helper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(
    private authService: AuthService,
    private router: Router,
    private helperService: HelperService
  ) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();
    // console.log('added')
    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (this.authService.accessToken) {
      newReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + this.authService.accessToken
        ).set('Time-Zone', timeZone),
      });
    } else {
      newReq = req.clone({
        headers: req.headers.set('Time-Zone', timeZone),
      });
    }

    // Response
    return next.handle(newReq).pipe(
      switchMap((response) => {
        return of(response);
      }),
      catchError((error) => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 0:
              return this.showError(error.statusText);
            case 500:
              return this.showError(error);
            case 404:
              return this.showError(error);
            case 400:
              return this.showError(error);
            case 401:
              this.helperService.showError('', 'Please login to continue');
              this.helperService.clearStorage();
              this.authService.authenticated = false;
              this.authService.redirectLink = this.router.url;
              this.authService.signOutApp();
              this.router.navigate(['sign-in']);
              return throwError(error.error.message);
            case 422:
              return this.showFormError(error);
            default:
              return this.showError(error);
          }
        }

        return throwError(error);
      })
    );
  }

  showError(error: any) {
    // console.log('Server Error');
    // console.log(error);
    return throwError(error);
  }

  showFormError(error: any) {
    // console.log('Server Error');
    // console.log(error);
    return throwError(error);
  }
}

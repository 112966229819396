<div class="mb-5" *ngIf="showActive">
  <h6>Active</h6>
  <div *ngIf="wallets.length > 0">
    <div class="transaction-wrapper">
      <div class="box box-100 box-min">
        <h5 class="mb-5" *ngIf="title">{{ title }}</h5>
        <a
          [routerLink]="'/transactions/view/' + wallet.id"
          routerLinkActive="router-link-active"
          *ngFor="let wallet of wallets"
          class="transaction mb-3"
        >
          <div class="left">
            <div class="icon">
              {{ getInitials(wallet.beneficiary.accountName) | uppercase }}
            </div>
            <div class="details">
              <h6 class="name">{{ wallet.beneficiary.accountName }}</h6>
              <h6 class="date">{{ wallet.createdAt | date }}</h6>
              <h6 class="status">
                <app-time-remaining
                  [date]="wallet.expireAt"
                ></app-time-remaining>
              </h6>
            </div>
          </div>
          <div class="right">
            <h6 class="price">
              {{ wallet.asset }}
              <span *ngIf="wallet.assetAmount">{{
                wallet.assetAmount | num
              }}</span>
            </h6>
            <h6 class="price">
              Active
            </h6>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div
    *ngIf="wallets.length == 0"
    style="min-height: 100px"
    class="box box-100 box-min"
  >
    <p>No active transactions!</p>
  </div>
</div>

<h6 *ngIf="showActive">Completed</h6>
<div *ngIf="transactions.length > 0">
  <div class="transaction-wrapper">
    <div class="box box-100 box-min">
      <h5 class="mb-5" *ngIf="title">{{ title }}</h5>
      <a
        *ngFor="let trx of transactions"
        class="transaction mb-3"
      >
        <div class="left">
          <div class="icon">
            {{ getInitials(trx.beneficiary.accountName) | uppercase }}
          </div>
          <div class="details">
            <h6 class="name">{{ trx.beneficiary.accountName }}</h6>
            <h6 class="date">{{ trx.createdAt | date }}</h6>
            <h6 class="status">
              <app-status-dsp [status]="trx.status"></app-status-dsp>
            </h6>
          </div>
        </div>
        <div class="right">
          <h6 class="price">{{ trx.currency }} {{ trx.amount | num }}</h6>
          <h6 class="price">
            <small
              >{{ trx.withdrawalCurrency }}
              {{ trx.withdrawalAmount | num }}</small
            >
          </h6>
        </div>
      </a>
    </div>
  </div>
</div>

<div *ngIf="transactions.length == 0" class="box box-100 box-min">
  <app-not-found
    [icon]="'no-transaction.svg'"
    [title]="'No transactions to show'"
    [subtitle]="'All transactions carried out on Didalla would show here.'"
  ></app-not-found>
</div>

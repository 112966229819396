import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SendDataService {

  form : any = null
  banks : any = null
  toAmount: number = 0
  fromAmount: number = 0
  rate: number = 0
  fee: number = 0
  fromCurrency: string = ''
  toCurrency: string = ''
  feeCurrency: string = ''

  constructor() { }
}

import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/core/helper/helper.service';
import { WalletService } from 'src/app/core/wallet/wallet.service';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
})
export class TransactionDetailsComponent implements OnInit, OnDestroy {
  id: string = '';
  wallet: any = null;
  loading: boolean = true;
  title = '';
  currencies: any = null;
  fromCurrency: any = null;
  toCurrency: any = null;
  reloadInterval: any = null;
  timeInterval: any = null;
  remainingTime: number = 1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private walletService: WalletService,
    private helperService: HelperService,
    private router: Router,
    private httpClient: HttpClient
  ) {
    this.activatedRoute.params.subscribe((param: any) => {
      this.id = param.id;
    });
  }

  ngOnInit(): void {
    this.getCurrencies();
    this.reloadInterval = setInterval(() => {
      if (!this.wallet || !this.wallet.runExpired) {
        this.getCurrencies();
      }
    }, 30000);
  }

  getWallet() {
    this.walletService.findOne(this.id).subscribe({
      next: (data) => {
        this.wallet = data.data;
        if (this.wallet.runExpired == 'expired') {
          clearInterval(this.reloadInterval);
        }
        this.fromCurrency = this.currencies.filter(
          (item: any) => item.code == this.wallet.asset
        )[0];
        this.toCurrency = this.currencies.filter(
          (item: any) => item.code == this.wallet.quote
        )[0];
        clearInterval(this.timeInterval);
        this.timeInterval = setInterval(() => {
          this.calculateRemainingTime(this.wallet.runExpireAt);
        }, 1000);
        this.loading = false;
      },
      error: (error) => {
        this.router.navigate(['/transactions']);
      },
    });
  }

  calculateRemainingTime(date: any) {
    const now = new Date();
    const targetTime = new Date(date);
    this.remainingTime = Math.max(
      0,
      this.wallet.runExpired
        ? 0
        : Math.floor((targetTime.getTime() - now.getTime()) / 1000)
    );
    if (this.remainingTime == 0) {
      this.wallet.runExpired = true;
    }
  }

  getCurrencies() {
    return this.httpClient
      .get(this.helperService.getApiUrl() + `user/currency`)
      .subscribe((data: any) => {
        this.currencies = data.data;
        this.getWallet();
      });
  }

  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(
      remainingSeconds
    )}`;
  }

  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  getImage(currency: string){
    switch(currency){
      case 'USDT': {
        return 'assets/images/usdt.svg'
      }
      case 'GBP': {
        return 'assets/images/united-kingdom.png'
      }
      case 'USD': {
        return 'assets/images/united-states.png'
      }
      default: {
        return 'assets/images/united-states.png'
      }
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.reloadInterval);
    clearInterval(this.timeInterval);
  }
}

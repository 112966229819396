import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-time-remaining",
  templateUrl: "./time-remaining.component.html",
  styleUrls: ["./time-remaining.component.scss"],
})
export class TimeRemainingComponent implements OnInit {
  timeInterval: any = null;
  remainingTime: number = 1;
  @Input() date: string = ''

  constructor() {}

  ngOnInit(): void {
    clearInterval(this.timeInterval);
    this.timeInterval = setInterval(() => {
      this.calculateRemainingTime();
    }, 1000);
  }

  calculateRemainingTime() {
    const now = new Date();
    const targetTime = new Date(this.date);
    this.remainingTime = Math.max(
      0,Math.floor((targetTime.getTime() - now.getTime()) / 1000)
    );
  }
  
  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(
      remainingSeconds
    )}`;
  }

  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  ngOnDestroy(): void {
    clearInterval(this.timeInterval);
  }
}

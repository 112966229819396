<div *ngIf="!loading" class="row mt-5">
  <div class="col-lg-6 offset-lg-3">
    <div class="row">
      <div class="col-12">
        <div class="header">
          <div routerLink="/transactions" class="back-icon">
            <mat-icon svgIcon="feather:chevron-left"></mat-icon>
          </div>
          <ng-container>
            <p *ngIf="remainingTime > 0">
              Expires in: {{ formatTime(remainingTime) }}
            </p>
            <app-status-dsp
              *ngIf="remainingTime <= 0"
              [status]="'expired'"
            ></app-status-dsp>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="transfer">
          <div class="currency-1">
            <div class="icon">
              <!-- <img [src]="'assets/images/united-states.png'" alt="" />
              <div class="name">US Dollar</div>
              <div class="code">USD</div> -->
              <img [src]="getImage(fromCurrency.code)" alt="" />
              <div class="name">{{ fromCurrency.name }}</div>
              <div class="code">{{ fromCurrency.code }}</div>
            </div>
          </div>
          <img src="assets/images/Line 1.svg" alt="" />
          <div class="currency-2">
            <div class="icon">
              <img src="assets/images/nigeria.png" alt="" />
              <div class="name">{{ toCurrency.name }}</div>
              <div class="code">{{ toCurrency.code }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="box box-100 status-box">
          <div class="waiting step">
            <ng-container *ngIf="!wallet.runExpired">
              <div class="circle active">
                <mat-icon svgIcon="feather:check"></mat-icon>
              </div>
              <div class="details">
                <h5>Active</h5>
                <h6 style="word-wrap: break-word">
                  Waiting for deposits <br />
                  <div
                    *ngIf="wallet.address && wallet.network; else accountDetails"
                  >
                    <small>{{ wallet.address }} ({{ wallet.network }})</small>
                  </div>
                  <ng-template #accountDetails>
                    <small>10688399 (Didalla Global Ltd) sort-code: 04-00-03</small>
                  </ng-template>
                </h6>
                <ul class="list-group">
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <span class="font-weight-bold">Account Name:</span>
                    <span>{{ wallet.beneficiary.accountName }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <span class="font-weight-bold">Account Number:</span>
                    <span>{{ wallet.beneficiary.accountNumber }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <span class="font-weight-bold">Bank Name:</span>
                    <span>{{ wallet.beneficiary.bankName }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <span class="font-weight-bold">Email:</span>
                    <span>{{ wallet.beneficiary.email }}</span>
                  </li>
                  <ng-container *ngIf="!wallet.network && !wallet.address">
                    <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <span class="font-weight-bold">Amount to pay:</span>
                    <span>{{ wallet.assetAmount }} {{wallet.asset}}</span>
                  </li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
            <ng-container *ngIf="wallet.runExpired">
              <div class="circle danger">
                <mat-icon svgIcon="feather:check"></mat-icon>
              </div>
              <div class="details">
                <h5>Expired</h5>
                <h6>
                  Transaction expired. Please initiate a new transaction to
                  continue.
                </h6>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <app-transactions
        [transactions]="wallet.transactions ?? []"
      ></app-transactions>
    </div>
  </div>
</div>
<app-page-loader *ngIf="loading"></app-page-loader>
